<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'checkout-summary-status',
  components: {
    BaseIcon,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="flex items-center justify-center px-5 pb-5 md:px-10">
    <div class="flex flex-col items-center">
      <div
        class="flex h-24 w-24 items-center justify-center rounded-full"
        :class="[order.statusLabel?.iconClass || order.statusLabel?.color]"
      >
        <base-icon :svg="order.statusLabel?.icon" :size="56" />
      </div>
      <h2 class="mt-6 text-2xl font-bold">
        {{ order.statusLabel?.heading }}
      </h2>
    </div>
  </div>
</template>
